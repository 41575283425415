import React from 'react';
import { FaLinkedin, FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="content">
      <div className="footer-left">
      <a href="https://dgn.org/Impressum">
      <button>Impressum</button>
      </a>
      <a href="https://dgn.org/datenschutz">
      <button>Datenschutz</button>
      </a>
      <a href="https://dgn.org/kontakt">
      <button>Kontakt</button>
      </a>
      </div>
      <div className="footer-right">
      <a href="https://www.linkedin.com/company/deutsche-gesellschaft-f%C3%BCr-neurologie/">
      <FaLinkedin className="social-icon" />
      </a>
      <a href="https://www.facebook.com/dgneurologie/">
      <FaFacebook className="social-icon" />
      </a>
      <a href="https://www.instagram.com/dgn_ev/">
      <FaInstagram className="social-icon" />
      </a>
      <a href="https://www.youtube.com/channel/UCY3dM9yVeriuVZG2R0svhKQ">
      <FaYoutube className="social-icon" />
      </a>
      </div>
      </div>
    </footer>
  );
};

export default Footer;