import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import banner from '../../banner.png'
import './start.css';

const Start = () => {

  const [width, setWidth] = useState('100');
  const [height, setHeight] = useState('1140');
  const [showBorder, setShowBorder] = useState(true);

  const handleWidthChange = (e) => {
    setWidth(e.target.value);
  };

  const handleHeightChange = (e) => {
    setHeight(e.target.value);
  };

  const handleBorderChange = (e) => {
    setShowBorder(e.target.checked);
  };

  return (
    <div className="content-container">
      <img className="banner" src={banner} alt="banner" />

      <h2 className="content-title">Adjust Form Parameters</h2>

      <div className='inputs-container'>
      <div className="input-container">
        <label>
          <h3 className="content-subtitle">Width [%]</h3>
          <input
            className="input-range"
            type="range"
            min="0"
            max="100"
            value={width}
            onChange={handleWidthChange}
          />
          <input
            className="input-number"
            type="number"
            min="0"
            max="100"
            value={width}
            onChange={handleWidthChange}
          />
        </label>
      </div>
      <div className="input-container">
        <label>
          <h3 className="content-subtitle">Height [px]</h3>
          <input
            className="input-range"
            type="range"
            min="400"
            max="1140"
            value={height}
            onChange={handleHeightChange}
          />
          <input
            className="input-number"
            type="number"
            min="400"
            max="1140"
            value={height}
            onChange={handleHeightChange}
          />
        </label>
      </div>
      <div className="input-container">
        <label>
          <h3 className="content-subtitle">Show Border</h3>
          <input
            type="checkbox"
            checked={showBorder}
            onChange={handleBorderChange}
          />
        </label>
      </div>
      </div>
      
      <Link to="/">
        <div className="start-button">
          <button>Back to home</button>
        </div>
      </Link>

      <iframe
        title="Embedded Form"
        width={`${width}%`}
        height={height}
        src="https://tools.medteconline.de/fillform/d23ac2ae4ef34bd3ab4da5bd0efe1502"
        frameborder={showBorder ? 1 : 0}
        // allowFullScreen
      ></iframe>
    </div>
  );
};

export default Start;