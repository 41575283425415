import Footer from '../components/footer/footer';
import Start from '../components/start/start';
import Header from '../components/header/header';

function StartSurvey() {
  return (
    <div>
      <Header />
      <Start />
      <Footer />
    </div>
  );
}

export default StartSurvey;
