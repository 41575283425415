// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: white;
    color: black;
    border-top: 1px solid black;
    padding: 40px 0px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1140px;
  }
  
  .footer-left {
    display: flex;
    gap: 10px;
  }
  
  .footer-right {
    display: flex;
    padding: 0px 20px;
    gap: 10px;
  }
  
  button {
    background-color: transparent;
    font-size: medium;
    color: #23256c;
    padding: 8px 12px;
    cursor: pointer;
    border: none;
    outline: none;
  }
  
  .social-icon {
    font-size: 1.8rem;
    padding: 0px 0px;
    color: #23256c;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,2BAA2B;IAC3B,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,SAAS;EACX;;EAEA;IACE,aAAa;IACb,iBAAiB;IACjB,SAAS;EACX;;EAEA;IACE,6BAA6B;IAC7B,iBAAiB;IACjB,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,eAAe;EACjB","sourcesContent":[".footer-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 10px;\n    background-color: white;\n    color: black;\n    border-top: 1px solid black;\n    padding: 40px 0px;\n  }\n\n  .content {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 1140px;\n  }\n  \n  .footer-left {\n    display: flex;\n    gap: 10px;\n  }\n  \n  .footer-right {\n    display: flex;\n    padding: 0px 20px;\n    gap: 10px;\n  }\n  \n  button {\n    background-color: transparent;\n    font-size: medium;\n    color: #23256c;\n    padding: 8px 12px;\n    cursor: pointer;\n    border: none;\n    outline: none;\n  }\n  \n  .social-icon {\n    font-size: 1.8rem;\n    padding: 0px 0px;\n    color: #23256c;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
