import Footer from '../components/footer/footer';
import Form from '../components/form/form';
import Header from '../components/header/header';

function Root() {
  return (
    <div>
      <Header />
      <Form />
      <Footer />
    </div>
  );
}

export default Root;
