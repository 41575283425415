import logo from '../../logo.png'
import React, { useState } from 'react';
import { FaSearch, FaBars } from 'react-icons/fa';
import './header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header-container">
      <div className="content">
      <div className="menu-icon" onClick={toggleMenu}>
      <a href="https://dgn.org">
        <FaBars />
      </a>
      </div>
      <div className="logo">
      <a href="https://dgn.org">
        <img src={logo} alt="logo" />
      </a>
      </div>
      <div className="search-and-login">
        <div className="search-icon">
        <a href="https://dgn.org">
          <FaSearch />
          </a>
        </div>
        <div className="login-button">
        <a href="https://dgn.org/auth/login">
          <button>Mitglieder-Login</button>
          </a>
        </div>
      </div>
      </div>
    </header>
  );
};

export default Header;