// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    /* border-bottom: 7px solid black; */
    padding: 20px 0px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1140px;
  }

  
  .menu-icon,
  .search-icon,
  .login-button {
    padding: 0 20px;
    cursor: pointer;
  }

  .menu-icon svg,
  .search-icon svg {
  font-size: 1.5rem;
  color: #23256c;
}
  
  .logo img {
    max-width: 50%;
    height: auto;
  }

  .login-button button {
    background-color: transparent;
    border: 1px solid #23256c;
    color: #23256c;
    border-radius: 10px;
    padding: 8px 16px;
    font-weight: bold;
    font-size: large;
    cursor: pointer;
  }
   
  .login-button button:hover {
    background-color: #23256c;
    color: #fff;
  }

  .search-and-login {
    display: flex;
    align-items: center;
  }`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;IACvB,oCAAoC;IACpC,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;EACf;;;EAGA;;;IAGE,eAAe;IACf,eAAe;EACjB;;EAEA;;EAEA,iBAAiB;EACjB,cAAc;AAChB;;EAEE;IACE,cAAc;IACd,YAAY;EACd;;EAEA;IACE,6BAA6B;IAC7B,yBAAyB;IACzB,cAAc;IACd,mBAAmB;IACnB,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB","sourcesContent":[".header-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: white;\n    /* border-bottom: 7px solid black; */\n    padding: 20px 0px;\n  }\n\n  .content {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 1140px;\n  }\n\n  \n  .menu-icon,\n  .search-icon,\n  .login-button {\n    padding: 0 20px;\n    cursor: pointer;\n  }\n\n  .menu-icon svg,\n  .search-icon svg {\n  font-size: 1.5rem;\n  color: #23256c;\n}\n  \n  .logo img {\n    max-width: 50%;\n    height: auto;\n  }\n\n  .login-button button {\n    background-color: transparent;\n    border: 1px solid #23256c;\n    color: #23256c;\n    border-radius: 10px;\n    padding: 8px 16px;\n    font-weight: bold;\n    font-size: large;\n    cursor: pointer;\n  }\n   \n  .login-button button:hover {\n    background-color: #23256c;\n    color: #fff;\n  }\n\n  .search-and-login {\n    display: flex;\n    align-items: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
